import {Directive, HostBinding, HostListener} from "@angular/core";

@Directive({
    selector: '[link]'
})
export class LinkDirective{

    @HostListener('mouseenter') onMouseEnter(){
        this.ponteiro = 'pointer';
    }

    @HostBinding('style.cursor') ponteiro : string;

}
// cursor:pointer