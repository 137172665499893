import {Component, Input} from '@angular/core';
import {Message} from "primeng/components/common/api";

@Component({
    selector: 'core-caixa-alerta',
    templateUrl: './caixa-alerta.component.html',
})
export class CaixaAlertaComponent {
    private _mensagem: string;
    private msg : Message[]  = [];
    private severity : string;

    @Input() titulo : string;
    @Input() icone : string;
    @Input() semPadding : boolean = false;
    @Input() primeng: boolean = true;
    @Input() tipo;
    @Input() set mensagem(valor){
        if(valor) {
            this.icone = this.tipo;
            this._mensagem = valor;
            this.severity = this.tipo;
            switch (this.tipo) {
                case 'warning':
                    this.titulo = 'Aviso!';
                    break;
                case 'success':
                    this.titulo = 'Sucesso!';
                    this.icone = 'fa-check';
                    break;
                case 'danger':
                    this.titulo = 'Erro!';
                    this.icone = 'fa-close';
                    this.severity = 'error';
                    break;
                default:
                case 'success':
                    this.titulo = 'Informação!';
                    break;
            }
            if(this.primeng) {
                this.msg = [{severity: this.severity, summary: this.titulo, detail: valor}];
            }
        }
    }
    getStyle(){
        if(!this.semPadding){
            return {'top': 'none'};
        }
    }

}