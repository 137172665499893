import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {LocalStorage, LocalStorageService} from "ng2-webstorage";
import {ConfiguracaoService} from "../../admin/base/configuracao/service/configuracao.service";
import {Router} from "@angular/router";
import {AuthService} from "../service/auth.service";
import {getConfigServidor, getSubDominio} from "../../config/configuracao";
import {TituloService} from "../../core/service/titulo.service";
import {DadosempresaService} from "../../admin/comum/dadosempresa/service/dadosempresa.service";


@Component({
    selector: 'auth-entrar-component',
    templateUrl: './entrar.component.html',
    styleUrls: [
        './entrar.component.css'
    ]

})
export class EntrarComponent  implements OnInit, OnDestroy{

    @LocalStorage() usuario;
    enviando: boolean = false;
    public formulario       : any       = {usuario: '', senha: ''};
    public enviado          : boolean   = false;
    public progress         : any       = {valor: 0, mensagem: '', totalEtapas: 3};
    public resposta         : any = {tipo:'', mensagem:''};
    
    @Output() acessou = new  EventEmitter();
    @Input() redirecionar  : boolean= true;



    constructor(
        private authService     : AuthService,
        public configuracao     : ConfiguracaoService,
        public localStorage : LocalStorageService,
        public router           : Router,
        public titulo           : TituloService,
        public dadosEmpresa     : DadosempresaService,
    ){
        this.titulo.set('Acesso ao Sistema');
    }

    ngOnInit(){

    }
    ngOnDestroy(){
        delete this.formulario;
        delete this.enviado;
        delete this.progress;
        delete this.resposta;

    }


    enviarForm(){
        this.enviado            = true;
        this.resposta.tipo      = '';
        this.resposta.mensagem  = '';
        this.progress.mensagem  = 'Validando Usuário e senha';
        this.progress.valor     = 1;

        this.authService.validaUsuario([
            {nome: 'usuario'  , valor : this.formulario.usuario, descricao: 'E-mail'},
            {nome: 'senha'    , valor : this.formulario.senha, descricao: 'Senha'},
            {nome: 'dominio'  , valor : getSubDominio(), descricao: 'Domínio'},
        ],'caixa-retorno').subscribe(
            resultado=> {
                if(Object.keys(resultado).length === 0){
                    this.enviado            = false;
                    this.resposta.mensagem  = "Usuário ou senha inválidos";
                    this.resposta.tipo      = "danger";
                }else {
                    this.usuario = resultado;
                    this.localStorage.store('permissoes', this.usuario.scope.split(' '));
                    this.getConfig();
                }
            },
            erro=>{
                this.enviado            = false;
                this.resposta.mensagem  = erro.retorno;
                this.resposta.tipo      = "danger";
            }
        );

    }
    private getConfig(){
        this.progress.mensagem   = 'Buscando configurações do usuário';
        this.progress.valor++;

        this.configuracao.buscaTodos().subscribe(
            (resultado:any)=> {
                this.progress.mensagem  = 'Tudo ok :D, você será transferido para página inicial';
                this.progress.valor++;
                getConfigServidor();
                this.localStorage.clear('menu');
                this.configuracao.atualizaTodos(resultado._embedded.configuracao[0]);

                this.configuracao.buscaTodosUsuario().subscribe(
                    (resultado:any)=>{
                        this.localStorage.store('configuracaousuario', resultado._embedded.configuracao_usuario[0]);
                        this.getFilial();
                    },
                    erro=>console.log(erro)
                );


            },
            erro =>console.log(erro)
        );

    }

    private getFilial(){
        this.progress.mensagem   = 'Buscando Filial';
        this.progress.valor++;

        this.dadosEmpresa.get(1).take(1).subscribe(
            resultado =>{
                this.localStorage.store('filial', resultado);

                if(this.redirecionar) {
                    this.router.navigate(['/']);
                }
                this.acessou.emit(true);
            }
        );
    }

}
