import {EventEmitter, Injectable} from "@angular/core";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class DadosempresaService{
    public itemEditar : any = null;
    public url : string = '/comum/dadosempresa';
    public servico = 'dadosempresa';
    alterarFilial  = new EventEmitter<any>();
    constructor(public requisicao : RequisicaoService){
        
    }
    
    get(id){
        return this.requisicao.get({
            url     : `${this.url}/${id}`,
        });
    }



} 