import {Injectable} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {CONFIGURACOES} from "../../config/configuracao";

@Injectable()
export class TituloService  {
    public configuracoes    : any       = CONFIGURACOES;

    constructor(
        public tituloService : Title
    ){

    }

    set(valor : string){
        let prefixo = this.configuracoes.sistemaNome;
        this.tituloService.setTitle(`${valor} - ${prefixo}`);
    }

}