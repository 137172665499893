import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {InnerComponentComponent} from "./component/inner-component.component";


@NgModule({
    imports     : [
        CommonModule
    ],
    exports     : [
        InnerComponentComponent,
    ],
    providers   : [
    ],
    declarations: [
        InnerComponentComponent,
    ]
})
export class InnerComponentModule { }
