import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {AlertModule, ProgressbarModule} from "ngx-bootstrap";
import {CoreSpinnerModule} from "../../core/component/spinner/spinner.module";
import {CoreBotaoModule} from "../../core/component/botao/botao.module";
import {EntrarRoutingModule} from "./entrar-routing.module";
import {EntrarComponent} from "./entrar.component";
import {CaixaAlertaModule} from "../../core/caixa-alerta/caixa-alerta.module";

@NgModule({
    imports     : [
        CommonModule,
        FormsModule,
        AlertModule,
        ProgressbarModule.forRoot(),
        CoreSpinnerModule,
        CoreBotaoModule,
        EntrarRoutingModule,
        CaixaAlertaModule,
    ],
    declarations: [
        EntrarComponent,
    ],
})
export class EntrarModule { }
