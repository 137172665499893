import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {LinkDirective} from "./directive/link.directive";


@NgModule({
    imports     : [
        CommonModule
    ],
    exports     : [
        LinkDirective
    ],
    providers   : [
    ],
    declarations: [
        LinkDirective
    ]
})
export class LinkModule { }
