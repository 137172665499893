import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import {Erro404RountinhgModule} from "./erro-404-routing.module";
import {Erro404Component} from "./erro-404.component";

@NgModule({
  imports: [
    CommonModule,
    // Erro404RountinhgModule
  ],
  exports: [
    Erro404Component
  ],
  declarations: [
    Erro404Component
  ]
})

export class Erro404Module { }
