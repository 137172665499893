import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthGuard} from "./auth-guard.service";
import {CommonModule} from "@angular/common";
import {Erro404Component} from "./admin/base/erro/erro-404/erro-404.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([
            {
                path: '',
                canActivate: [AuthGuard],
                loadChildren: 'app/admin/admin.module#AdminModule'
            },
            {
                path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule'
            },
            {path: '**'         ,  component: Erro404Component}
            // {path: 'login', loadChildren: 'app/admin/admin.module#AdminModule'},
            // { path: '403'       , component: Erro403Component },

        ]),
    ],
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule { }
