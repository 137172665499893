import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {EntrarComponent} from "./entrar.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
        { path: '', component: EntrarComponent}
    ]),
  ],
  exports: [
    RouterModule
  ],
})
export class EntrarRoutingModule { }
