import {AfterViewInit, Component, Input} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'core-botao',
    template: `
        <button *ngIf="tipo == 'submit' || tipo == 'button'" class="btn {{classe}}" [style]="sanitizer.bypassSecurityTrustStyle(styleButton)" [disabled]="estaProcessando || disabled" [type]="tipo" [innerHTML]="texto"></button>
        <a *ngIf="tipo == 'a'" class="btn {{classe}}" [type]="tipo"  [style]="sanitizer.bypassSecurityTrustStyle(styleButton)" [tooltip]="titulo" [innerHTML]="texto"></a>
        <a *ngIf="tipo == 'link'" class="{{classe}}" link [type]="tipo" [style]="sanitizer.bypassSecurityTrustStyle(styleButton)" [tooltip]="titulo" [innerHTML]="texto"></a>
    `
})
export class CoreBotaoComponent implements AfterViewInit{

    public textoBackup         : string = '';
    private estaProcessando     : boolean = false;
    @Input() classe             : string;
    @Input() disabled           : boolean = false;
    @Input() tipo               : string = 'submit';
    @Input() titulo             : string = '';
    @Input() texto              : string = '';
    @Input() styleButton        : string = '';
    @Input() textoProcessando   : string = `<i class='fa fa-refresh fa-spin'></i> <span class="hidden-xs">Processando...</span>`;

    constructor(
        private sanitizer:DomSanitizer,
    ){

    }

    @Input()
    public set processando(valor : boolean){
        if(valor){
            this.estaProcessando    = true;
            this.textoBackup        = this.texto;
            this.texto              = this.textoProcessando;
        }else{
            if(this.estaProcessando) {
                this.estaProcessando    = false;
                if(this.textoBackup) {
                    this.texto = this.textoBackup;
                }
            }
        }
        
    }

    ngAfterViewInit(){
    }

}