import {Injectable} from "@angular/core";
import {LocalStorage, LocalStorageService} from "ng2-webstorage";
import {RequisicaoService} from "../../../../core/service/requisicao.service";

@Injectable()
export class ConfiguracaoService {
    public url : string = '/base/configuracao';

    @LocalStorage() configuracao;
    @LocalStorage() configuracaousuario;
    constructor(
        public requisicao   : RequisicaoService, 
        public localStorage : LocalStorageService
    ){
        
    }


    buscaTodos(){
        return this.requisicao.get({
            url         : this.url,
            semToken    : true
        });
    }
    buscaTodosUsuario(){
        return this.requisicao.get({
            url         : this.url+'-usuario'
        });
    }
    
    atualizaTodos(configuracao : any){
        this.localStorage.store('configuracaoglobal', configuracao);
    }
    
    atualizaPosicao(chave, valor){
        let atualizar = {};
        atualizar[chave] = valor;
        this.configuracao = Object.assign(this.configuracao, atualizar);
        return this.requisicao.patch({
            url: `${this.url}/${chave}`,
            data : {valor : valor}
        });
    }

    atualizaPosicaoUsuario(chave, valor){
        let atualizar = {};
        atualizar[chave] = valor;
        this.configuracaousuario = Object.assign(this.configuracaousuario, atualizar);
        return this.requisicao.patch({
            url: `${this.url}-usuario/${chave}`,
            data : {valor : valor}
        });
    }

} 