import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ConfirmeModalComponent} from "./component/confirme.component";
import {AlertModalComponent} from "./component/alert.component";
import {InnerComponentModule} from "../inner-component/inner-component.module";


@NgModule({
    imports     : [
        CommonModule,
        InnerComponentModule
    ],
    exports     : [
       ConfirmeModalComponent,
        AlertModalComponent
    ],
    providers   : [
        
    ],
    declarations: [
        ConfirmeModalComponent,
        AlertModalComponent
    ],
    entryComponents:[
        ConfirmeModalComponent,
        AlertModalComponent
    ]
})
export class ModalModule { }
