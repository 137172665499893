import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {CoreBotaoComponent} from "./botao.component";
import {LinkModule} from "../../link/link.module";
import {TooltipModule} from "ngx-bootstrap";


@NgModule({
    imports     : [CommonModule, LinkModule, TooltipModule.forRoot()],
    exports     : [CoreBotaoComponent],
    providers   : [],
    declarations: [CoreBotaoComponent]
})
export class CoreBotaoModule { }
