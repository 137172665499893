import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from 'rxjs/Rx';

import {AlertModalComponent} from "../modal/component/alert.component";
import {BSModalContext, Modal} from 'ngx-modialog/plugins/bootstrap';
import {overlayConfigFactory} from 'ngx-modialog';
import {LocalStorage} from "ng2-webstorage";
import {CONFIGURACOES, getSubDominio} from "../../config/configuracao";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

@Injectable()
export class RequisicaoService  {

    @LocalStorage() usuario;
    headers = new HttpHeaders({'Content-Type':'application/json'});
    bkp;

    constructor(
        public http      : HttpClient,
        public router    : Router,
        public modal     : Modal
    ){}

    post(objeto) {
        let urlParams = this.paramns(objeto.urlParametros, new HttpParams());

        return this.http.post(
            this.getUrl(objeto.url, objeto.semToken),
            JSON.stringify(this.getData(objeto)),
            {headers:this.headers, params : urlParams}
        )
        .catch(err=>{ return this.formataErros(err, objeto);})
        ;
    }

    get(objeto) {
        let urlParams = this.paramns(objeto.data, new HttpParams());
        return this.http.get(
            this.getUrl(objeto.url, objeto.semToken),
            {headers:this.headers, params : urlParams}
        )
            .catch(err=>{ return this.formataErros(err, objeto);})
            ;
    }


    paramns(a , urlParams: HttpParams){
        for ( let prefix in a ) {
            urlParams = this.buildParams( prefix, a[ prefix ], urlParams );
        }

        return urlParams;
    }

    buildParams( prefix, obj,  urlParams ){
        if(obj instanceof Array){

            for(let i in obj) {

                urlParams = this.buildParams( prefix + "[" + ( typeof obj[i] === "object" || Array.isArray(obj[i]) ? i : "" ) + "]", obj[i], urlParams );
            }
            return urlParams;
        }else if(obj instanceof Object){
            for ( let name in obj ) {
                if(obj[ name ] instanceof Date){
                    obj[ name ] = JSON.stringify(obj[ name ]);
                }
                urlParams = this.buildParams( prefix + "[" + name + "]", obj[ name ], urlParams );
            }
            return urlParams;
        }else{
            if(obj !== null && obj !== undefined) {
                urlParams = urlParams.append(prefix, obj);
            }
            return urlParams;
        }
    }


    delete(objeto) {
        return this.http.delete(
            this.getUrl(objeto.url, objeto.semToken, this.getData(objeto)),
            {headers:this.headers,params: objeto.data}
        )
        .catch(err=>{ return this.formataErros(err, objeto);})
        ;
    }
    
    deleteAll(objeto){
        return this.http
            .request('DELETE', this.getUrl(objeto.url, objeto.semToken), {
                body: this.getData(objeto),
            })
            .catch(err=>{ return this.formataErros(err, objeto);})
            ;
    }

    patch(objeto){
        // console.log({objetoRequisicaoService: objeto});

        let urlParams = this.paramns(objeto.urlParametros, new HttpParams());
        return this.http.patch(
            this.getUrl(objeto.url, objeto.semToken),
            JSON.stringify(this.getData(objeto)),
            // objeto,
            {headers:this.headers, params : urlParams}
        )
        .catch(err=>{
            console.log({erroRequisicaoPatch:err});
            
             return this.formataErros(err, objeto);})
        ;
    }

    private formataErros(erro : any, objeto){
        let retorno =  erro.error;
        switch (erro.status){
            case 403:
                this.router.navigate(['/403']);

                retorno =  'Você não permissão para acessar';
                break;
            case 404:
            case 400:
                retorno = erro.error.detail;
                break;
            case 401:
                this.router.navigate(['/auth']);
                retorno =  'Você não esta logado no sistema';
                break;
            case 422:
                retorno = this.formataMensagem422(retorno, objeto);
                break;

        }
        return Observable.throw({codigo: erro.status,  retorno : retorno});
    }





    public getUrl(url, semToken?, parametros = {}) {
        parametros['dominio'] = getSubDominio();

        if (typeof semToken == 'undefined' && semToken != false) {
            if (!this.usuario) {
                this.router.navigate(['/auth']);
            }
            parametros['access_token'] = this.usuario.access_token;
        }
        if (parametros) {
            for (let parametro in parametros){
                if (url.indexOf('?') == -1) {
                    url += '?';
                } else {
                    url += '&';
                }
                url += parametro + '=' + parametros[parametro];
            }
        }
            return CONFIGURACOES.urlApi + url;


    }



    public getData(objeto){
        let retorno = {};
        if(typeof objeto.data != 'undefined'){
            retorno = objeto.data
        }
        if(typeof objeto.campos != 'undefined'){
            for (let a of objeto.campos) {
                retorno[a.nome] = a.valor;
            }
        }
        
        return retorno;

    }


    protected formataMensagem422(erro, objeto){
        let mensagem = '';
        if(typeof  objeto.campos != 'undefined') {
            let erros = erro.validation_messages;
            for(let campo in erros){
                for(let mensagemErro in erros[campo]){
                    let index = objeto.campos.findIndex(item => item.nome == campo);
                    if(index != -1) {
                        mensagem += `${objeto.campos[index].descricao} ${erros[campo][mensagemErro]}<br>`;
                    }else{
                        mensagem += `${campo} ${erros[campo][mensagemErro]}<br>`;
                    }
                }
            }
        }
        return mensagem;
    }

    modalAlerta(titulo, mensagem){
        this.modal
            .open(AlertModalComponent,  overlayConfigFactory({
                titulo: titulo,
                descricao: mensagem,
                onOk: (dialog) => {
                    dialog.close(true);
                },
                onCancelar: (dialog)=>{
                    dialog.dismiss();
                }
            }, BSModalContext));
    }
}