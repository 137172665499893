import {Component} from '@angular/core';

@Component({
    selector: 'admin-erro-404',
    templateUrl: './erro-404.component.html',
})
export class Erro404Component  {


    constructor(){
    }

    voltar(){

    }
}
