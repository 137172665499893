import {Injectable} from '@angular/core';

declare const moment:any;
@Injectable()
export class DataService  {

    constructor(
    ){

    }

    getDataHora(data){
        if(!data){
            return null;
        }
        return new moment(data).format('YYYY-MM-DD HH:mm:ss');
    }

    getData(data){
        if(!data){
            return null;
        }
        return new moment(data).format('YYYY-MM-DD');
    }
}