

export const CONFIG_CRM =
{
    sistemaUrl  : 'http://seons.com.br',
    sistemaNome : 'Seons - Sistemas de Gestão',
    renderComponent: {

    },

};


