import {CONFIG_BASE} from './base';
import {CONFIG_CRM} from './crm';

var CONFIG_CLIENTE : any;

export function MergeRecursive(obj1, obj2) {

    for (var p in obj2) {
        try {
            if ( typeof obj2[p] == 'object') {
                obj1[p] = MergeRecursive(obj1[p], obj2[p]);

            }else if(Array.isArray(obj2[p])){
                obj1[p] = obj1[p].concat(obj2[p]);
            } else {
                // if(typeof obj2[p] == 'object'){
                //     obj1[p] - Object.assign({}, obj1[p], obj2[p]);
                // }else {
                    obj1[p] = obj2[p];
                // }
            }

        } catch(e) {
            // Property in destination object not set; create it and set its value.
            obj1[p] = obj2[p];

        }
    }
    return obj1;
}
export function configToArray(a){
    return Object.keys(a).map(key => a[key]);
}

function getConfigCliente() : any{
    if(!CONFIG_CLIENTE) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", 'config.json', false); // false for synchronous request
        xmlHttp.send(null);
        CONFIG_CLIENTE = JSON.parse(xmlHttp.responseText);
    }
    return CONFIG_CLIENTE
}
export function getConfigServidor(){
    // console.log(window.localStorage.hasOwnProperty('ng2-webstorage|configuracao'));
    // if(!window.localStorage.hasOwnProperty('ng2-webstorage|configuracao') && window.localStorage.getItem('ng2-webstorage|configuracao')) {
        if (!CONFIG_CLIENTE) {
            getConfigCliente();
        }
        let usuario = window.localStorage.getItem('ng2-webstorage|usuario');
        if(usuario && usuario!= ''&& usuario != 'null' && usuario['access_token'] != '') {
            usuario = JSON.parse(usuario);
            // console.log(CONFIG_CLIENTE.urlApi + '/base/configuracao?access_token='+usuario['access_token']);
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", `${CONFIG_CLIENTE.urlApi}/base/configuracao?access_token=${usuario['access_token']}&dominio=${getSubDominio()}`, false); // false for synchronous request
            xmlHttp.send(null);
            if(xmlHttp.status == 200) {
                var json = JSON.parse(xmlHttp.responseText);
                window.localStorage.setItem('ng2-webstorage|configuracao', JSON.stringify(json._embedded.configuracao[0]));
            }
        }
    // }

    window.localStorage.setItem('ng2-webstorage|tela', JSON.stringify({component: '', exibir: false}));
}
export function getSubDominio(){
    let tamanho = 4;
    let posSplit = 0;
    if(window.location.host.indexOf('www') !== -1){
        tamanho = 5;
        posSplit = 1;
    }
    let split = window.location.host.split('.');

    if(split.length == tamanho){
        return split[posSplit];
    }else{
        return CONFIGURACOES.dominio
    }
}
export function getConfigServidorGlobal(){
    // if(!window.localStorage.hasOwnProperty('ng2-webstorage|configuracaoglobal') && window.localStorage.getItem('ng2-webstorage|configuracaoglobal')) {
        if (!CONFIG_CLIENTE) {
            getConfigCliente();
        }
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", CONFIG_CLIENTE.urlApi + '/base/configuracao', false); // false for synchronous request
        xmlHttp.send(null);
        var json = JSON.parse(xmlHttp.responseText);
        window.localStorage.setItem('ng2-webstorage|configuracaoglobal', JSON.stringify(json._embedded.configuracao[0]));
    // }
}


export const CONFIGURACOES = MergeRecursive(MergeRecursive(CONFIG_BASE, CONFIG_CRM), getConfigCliente());
