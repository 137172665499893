export const CONFIG_BASE =
{
    renderConfiguracao: {},
    data: {
        pt_br : {
            firstDayOfWeek: 0,
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            dayNamesShort: [ "dom","seg","ter","qua","qui","sex","sab" ],
            dayNamesMin: ["D","S","T","Q","Q","S","S"],
            monthNames: [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ],
            monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ]
        },
        padraoDataComponent     : 'dd/mm/yy',
        padraoDataHoraComponent : 'dd/mm/yy H:mm:ss',
        padraoDataPipe          : 'DD/MM/Y',
        padraoDataHoraPipe      : 'DD/MM/Y HH:mm:ss',
        dataAtual               : new Date(),
    }
};
