import {Injectable} from "@angular/core";

@Injectable()
export class ConfigService{

    getLocale(){
        return 'pt-BR';
    }
    
    getBaseHref(){
        return '/';
    }

  
}