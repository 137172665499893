import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {AlertModule} from "ngx-bootstrap";
import {CaixaAlertaComponent} from "./component/caixa-alerta.component";
import {GrowlModule} from "primeng/components/growl/growl";


@NgModule({
    imports     : [
        CommonModule,
        AlertModule.forRoot(),
        GrowlModule,
    ],
    exports     : [CaixaAlertaComponent],
    providers   : [],
    declarations: [CaixaAlertaComponent]
})
export class CaixaAlertaModule { }
