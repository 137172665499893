import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {RENDER_COMPONENTS} from "../../../config/render-component";


@Component({
  selector: 'core-inner-component',
  template: '<div #parent #texto></div>',
})
export class InnerComponentComponent implements OnInit{

  private _html : any;

  @ViewChild('parent', {read: ViewContainerRef}) parent: ViewContainerRef;
  @ViewChild('texto') texto : ElementRef;
  @Input() set html(valor : any){
    this._html = valor;
    this.addComponent();
  }


  constructor(private componentFactoryResolver: ComponentFactoryResolver){

  }

  ngOnInit(){
    this.addComponent();
  }

  addComponent(){
    var aux = this._html.split(' ');
    var texto = '';

    for(let item of aux){
      var existe = RENDER_COMPONENTS.filter(comp => comp.selector == item);
      if(existe.length){
        this.parent.clear();
        this.parent.createComponent(this.componentFactoryResolver.resolveComponentFactory(<any>existe[0].component));
      }else{
        texto = texto+ ' '+item;
      }

    }
    this.texto.nativeElement.innerHTML = texto;
  }

}
