import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {CoreSpinnerComponent} from "./spinner.component";


@NgModule({
    imports     : [CommonModule],
    exports     : [CoreSpinnerComponent ],
    providers   : [],
    declarations: [CoreSpinnerComponent ]
})
export class CoreSpinnerModule { }
