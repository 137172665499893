import {Injectable} from '@angular/core';
import {RequisicaoService} from "../service/requisicao.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

@Injectable()
export class PdfService  {

    private headers = new HttpHeaders({'Content-Type':'application/json'});

    constructor(
        private http        : HttpClient,
        public requisicao : RequisicaoService
    ){}


    gerar(url, obj){

        return this.http.post(
            this.requisicao.getUrl(url),
            JSON.stringify(this.requisicao.getData(obj)),
            {headers:this.headers, responseType: 'blob'}
        )
            .retry(3);
    }
    get(url, obj?, responseType?){
        let urlParams = this.requisicao.paramns(obj, new HttpParams());

        if(!responseType){
            responseType = 'blob';
        }

        return this.http.get(
            this.requisicao.getUrl(url),
            {headers:this.headers, responseType:responseType, params: urlParams}
        )
            .retry(3);


    }



}