import {Component, HostListener, Input} from '@angular/core';

import {CloseGuard, DialogRef, ModalComponent} from 'ngx-modialog';
import {BSModalContext} from 'ngx-modialog/plugins/bootstrap';

export class ConfirmeModalComponentContext extends BSModalContext {
    public titulo: string;
    public descricao: string;
    onOk : any;
    onCancelar : any;
}

/**
 * A Sample of how simple it is to create a new window, with its own injects.
 */
@Component({
    selector: 'modal-confirme',
    templateUrl :'./modal.component.html',
})
export class ConfirmeModalComponent implements CloseGuard, ModalComponent<ConfirmeModalComponentContext> {
    @Input() context: ConfirmeModalComponentContext;
    enviado : boolean = false;

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if(event.key == 'Enter'){
            this.close();
        }
    }

    constructor(public dialog: DialogRef<ConfirmeModalComponentContext>) {
        this.context = dialog.context;
        // dialog.setCloseGuard(this);
    }

    close(){
        this.enviado = true;
        this.context.onOk(this.dialog);
    }


    dismiss(){
        this.context.onCancelar(this.dialog);
    }

    beforeDismiss(): boolean {
        return true;
    }

    beforeClose(): boolean {
        return true;
    }
}
