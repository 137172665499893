import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule,} from '@angular/forms';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AppRoutingModule} from "./app.routing";
import {RequisicaoService} from "./core/service/requisicao.service";
import {ModalModule} from 'ngx-modialog';
import {BootstrapModalModule} from 'ngx-modialog/plugins/bootstrap';
import {ModalModule as CoreModalModule} from "./core/modal/modal.module";
import {CanDeactivateGuard} from "./can-deactivate-guard.service";
import {Ng2Webstorage} from "ng2-webstorage";
import {SlimLoadingBarModule} from 'ng2-slim-loading-bar';
import {ConfigService} from "./config.service";
import {getConfigServidorGlobal} from "./config/configuracao";
import {AppComponent} from "./app.component";
import {AuthGuard} from "./auth-guard.service";
import {PdfService} from "./core/pdf/pdf.service";
import {ConfiguracaoService} from "./admin/base/configuracao/service/configuracao.service";
import {EntrarModule} from "./auth/entrar/entrar.module";
import {AuthService} from "./auth/service/auth.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {Erro404Module} from "./admin/base/erro/erro-404/erro-404.module";
import {ScrollToModule} from "ng2-scroll-to-el";
import {TituloService} from "./core/service/titulo.service";
import localePt from '@angular/common/locales/pt';
import {DadosempresaService} from "./admin/comum/dadosempresa/service/dadosempresa.service";
import {DataService} from "./core/service/data.service";
import {HttpClientModule} from "@angular/common/http";
import {HttpModule} from "@angular/http";

registerLocaleData(localePt, 'pt-BR');

// enableProdMode();
getConfigServidorGlobal();
@NgModule({
    declarations: [
        AppComponent,
    ],
 
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    CoreModalModule,
    Ng2Webstorage,
    SlimLoadingBarModule.forRoot(),
    ScrollToModule.forRoot(),
    EntrarModule,
      Erro404Module

  ],
  providers: [
    RequisicaoService,
    AuthService,
    CanDeactivateGuard,
    ConfigService,
    PdfService,
    ConfiguracaoService,
    AuthGuard,
    TituloService,
    DadosempresaService,
    DataService,
    { provide: LOCALE_ID, useValue: "pt-BR" }

      // ConfiguracaoService,

  // {
  //     provide: APP_BASE_HREF,
  //     deps: [ConfigService],
  //     useFactory: (configService) => configService.getBaseHref()
  //   },
  //   {
  //       provide: LOCALE_ID,
  //       deps: [ConfigService],
  //       useFactory: (configService) => configService.getLocale()
  //   },

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
