import {LocalStorage} from "ng2-webstorage";

export class ValidaPermissao {

    @LocalStorage() usuario;
    @LocalStorage() permissoes;


    validaAcao(scope : string) : boolean {
        if(!this.permissoes){
            return false;
        }

        if(!this.permissoes.includes(scope)){
            return false;
        }
        return true;
    }

}