'use strict';

import {Component, Input, OnDestroy} from '@angular/core';
import {toNumber} from "ngx-bootstrap/timepicker/timepicker.utils";

@Component({
    selector: 'core-spinner',
    templateUrl: './spinner.component.html',
})
export class CoreSpinnerComponent implements OnDestroy {
    public currentTimeout: any;
    public isDelayedRunning: boolean = false;

    @Input()
    public tamanho: number = 5;
    
    @Input()
    public delay: number = 300;
    
    @Input()
    public classe : string = 'spinner-admin';

    @Input()
    public set isRunning(value: any) {
        if (!value) {
            this.cancelTimeout();
            this.isDelayedRunning = false;
            return;
        }

        if (this.currentTimeout) {
            return;
        }

        this.currentTimeout = setTimeout(() => {
            this.isDelayedRunning = value;
            this.cancelTimeout();
        }, this.delay);
    }

    private cancelTimeout(): void {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = undefined;
    }

    ngOnDestroy(): any {
        this.cancelTimeout();
    }
    getStyle(){
        switch (toNumber(this.tamanho)){
            case 3:
                return {
                    width: '74px'
                };
            case 5:
            default:
                return {
                    width: '126px'
                };
        }
    }
}