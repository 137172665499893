import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import {ValidaPermissao} from "../../core/util/valida-permissao";
import {LocalStorage, LocalStorageService} from "ng2-webstorage";
import {RequisicaoService} from "../../core/service/requisicao.service";

@Injectable()
export class AuthService  {
    public validaPermissao  : ValidaPermissao = new ValidaPermissao();
    
    public redirecionar : boolean = true;
    

    @LocalStorage() usuario;
    
    constructor(
        public router: Router,
        public location:Location,
        public localStorage : LocalStorageService,
        public requisicao: RequisicaoService
    ){

    }

    estaLogado(){
        return this.localStorage.retrieve('usuario');
    }

    temAcesso(scope:string){
        return this.validaPermissao.validaAcao(scope);
    }


    atualizaUsuario(dados: any){
        this.usuario = Object.assign(this.usuario, dados);
    }
    
    
    sair(){
        this.localStorage.clear('menu');
        this.localStorage.clear('usuario');
        this.localStorage.clear('configuracaousuario');
        this.localStorage.clear('permissoes');
        this.localStorage.clear('filial');
        // location.href = '/auth/entrar'
        this.router.navigate(['/auth']);
    }

    validaUsuario(campos, idRetorno): any{
        return this.requisicao.post({
            url     :'/base/auth',
            campos  : campos,
            semToken: true,
            idRetorno:idRetorno,
        });
    }

    validaOauth(email : string, senha : string): any{
        return this.requisicao.post({
            url:'/oauth',
            data: {
                "client_id"     : email,
                "client_secret" : senha,
                "grant_type"    : "client_credentials"
            },
            semToken: true,
        });
    }
    enviaEmail(campos, idRetorno): any{
        return this.requisicao.post({
            url     :'/base/esqueci-minha-senha',
            campos  : campos,
            semToken: true,
        });
    }
    trocarSenha(campos): any{
        return this.requisicao.post({
            url     :'/base/recuperar-senha',
            campos  : campos,
            semToken: true,
        });
    }

    getUsuarioPeloToken(token): any{
        return this.requisicao.get({
            url     :'/base/usuario-token?access_token='+token,
            semToken: true
        });
    }

}